import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Containers from "../components/container"
import * as footerStyles from "./footer.module.css"
import { Icon } from '@iconify/react';
import bxsPhone from '@iconify/icons-bx/bxs-phone';
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const tel081 = "tel:081-766-7977";

export default function Footer () {
    return (
        <Containers>
            <div className={footerStyles.footerbackground}>
            <Container fluid="xl">
                <Row>
                    <Col md={3}></Col>
                    <Col md={4} className={footerStyles.colimg}><StaticImage src="../../static/image/logo-white.png" alt="Songthai-white" /></Col>
                    <Col md={2} className={footerStyles.colcenter}>
                        <ul className={footerStyles.bulletsphone}>
                            <li><a href={tel081} style={{ color:"#fff" }}><Icon icon={bxsPhone} height={18} /> 081-766-7977</a></li>
                        </ul>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row>
                    <Col><center><p className={footerStyles.plocation}>33/16-27 หมู่ 3 ถนนเพชรเกษม 110 หนองค้างพลู หนองแขม กทม. 10160</p></center></Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <h3 className={footerStyles.fontfooter}>งานการ์เม้นท์</h3>
                        <ul className={footerStyles.bulletsmenu}>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/เส้นด้ายเย็บ/">เส้นด้ายเย็บ</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายฟูน้อย-ฟูมาก/">ด้ายฟูน้อย/ฟูมาก</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ไนล่อน-โพลี-เย็บหนัง-เบาะ/">ไนล่อน/โพลี เย็บหนัง เบาะ</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายมัน-A-LON/">ด้ายมัน A-LON</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายเย็บโพ้งแซก/">ด้ายเย็บโพ้งแซก</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายสม็อก/">ด้ายสม็อก</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายเย็บผ้าใบ-เต้นท์/">ด้ายเย็บผ้าใบ, เต้นท์</Link></li>
                        </ul>
                        </Col>
                    <Col md={3} xs={6}>
                        <ul className={footerStyles.bulletsmenua}>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ด้ายเทียน/">ด้ายเทียน</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/เมจิกเทป/">เมจิกเทป</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/กรรไกร-กรรไกรก้ามปู/">กรรไกร/กรรไกรก้ามปู</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/น้ำมันจักร/">น้ำมันจักร</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/อะไหล่จักร/">อะไหล่จักร</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ไนล่อน-ซิป/">ไนล่อน ซิป</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/garment/ซิป-ตัวเลื่อน/">ซิป ตัวเลื่อน</Link></li>
                        </ul>
                    </Col>
                    <Col md={3} xs={6}>
                        <h3 className={footerStyles.fontfooter}>ด้ายเย็บกระสอบ</h3>
                        <ul className={footerStyles.bulletsmenu}>
                            <li><Link className={footerStyles.linkfooter} to="/product/sew-a-sack/ด้ายสปันเย็บกระสอบ/">ด้ายสปันเย็บกระสอบ</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/sew-a-sack/ด้าย-PP-multifilament-เเรงดึงสูง/">ด้าย PP multifilament เเรงดึงสูง</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/sew-a-sack/ด้าย-ไนล่อนเย็บกระสอบ/">ด้าย ไนล่อนเย็บกระสอบ</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/sew-a-sack/เครื่องจักรเย็บกระสอบ/">เครื่องจักรเย็บกระสอบ</Link></li>
                        </ul>
                        <h3 className={footerStyles.fontfooter}>อุปกรณ์ตัดเย็บและสินค้านำเข้า</h3>
                        <ul className={footerStyles.bulletsmenu}>
                            <li><Link className={footerStyles.linkfooter} to="/product-import/">แค็ตาล็อก สินค้านำเข้า</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/other-products/">สินค้าอื่นๆ</Link></li>
                        </ul>
                        </Col>
                    <Col md={3} xs={6}>
                        <h3 className={footerStyles.fontfooter}>ด้ายอุตสาหกรรม</h3>
                        <ul className={footerStyles.bulletsmenu}>
                            <li><Link className={footerStyles.linkfooter} to="/product/industrial-thread/spun-polyster-yarn/">SPUN POLYESTER YARN</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/industrial-thread/polyester-texture-yarn/">POLYESTER TEXTURE YARN</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/industrial-thread/nylon-yarn/">NYLON YARN</Link></li>
                            <li><Link className={footerStyles.linkfooter} to="/product/industrial-thread/cotton-yarn/">COTTON YARN</Link></li>
                        </ul>
                        </Col>
                </Row>
                <Row>
                    <Col className={footerStyles.colfootb}><center><p className={footerStyles.fontfooterb}>Songthaitextile – Factory, Industrial</p></center></Col>
                </Row>
                <Row>
                    <Col><center><p className={footerStyles.fontfooterc}>Songthaitextile – Gatsbyjs Theme 2021</p></center></Col>
                </Row>
            </Container>
            </div>
        </Containers>
    )
}